import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layouts/home'
import Card from '../components/card'
import SEO from '../components/seo'
import Meta, { keywords } from '../components/meta';

function keywordsMaker(category) {
  return keywords[category].map(c => `${c} nepal`)
}

export default function ListTemplate({ location, data }) {
  const splits = location.pathname.split('/')
  const renderList = () => {
    return data.allMarkdownRemark.edges.map(e => <Card frontmatter={e.node.frontmatter} key={e.node.frontmatter.path} />)
  }

  const currentCategoryLowerCase = splits[2];
  const currentCategory = currentCategoryLowerCase.titleize();

  let markets = [];

  data.allMarkdownRemark.edges.forEach(e => { markets = [...markets, ...e.node.frontmatter.markets] })

  const uniqMarkets = markets.filter(function (x, i, a) {
    return a.indexOf(x) == i;
  });

  const renderMarketLinks = () => {
    const marketLinks = uniqMarkets.map(m => <a title={`${currentCategory} in ${m}`} class="btn btn-sm btn-badge" href={`/services/${splits[2]}/${m}`}>{m}</a>)
    return <p class="reserve-description">
      <b class="reserve-filter">Locations:</b>{ marketLinks }
    </p>
  }

  return (
    <Layout>
      <SEO
        title={`Listing all ${currentCategory} in Nepal`}
        keywords={ keywordsMaker(currentCategoryLowerCase) }
        description={Meta[splits[2]]}
        meta={[
          {
            property: 'og:url',
            content: `http://www.ceremonynepal.com/services/${currentCategoryLowerCase}`
          },
          {
            property: 'og:image',
            content: `http://www.ceremonynepal.com/images/cover-images/ceremonynepal-${currentCategoryLowerCase}.jpg`
          }
        ]}
      />
      <section class="reserve-block">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h1>{`Listing ${currentCategory.titleize()} in Nepal`}</h1>
              { renderMarketLinks() }
            </div>
          </div>
        </div>
      </section>
      <div class="light-bg">
        <div class="container">
          <div class="row detail-options-wrap">
            { data.allMarkdownRemark && renderList() }
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ListByCategories($Category: String) {
    allMarkdownRemark(filter: { frontmatter: {  categories: { in: [$Category] } }}) {
      edges {
        node {
          frontmatter {
            path
            title
            location
            rating
            markets
            featured
            profileImage {
              childImageSharp {
                fixed(width: 420, height: 340) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
